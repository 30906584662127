<mat-card class="record-card" *ngIf="stockItem">
  <table class="table table-sm table-hover table-striped">
    <thead>
    <tr style="border:none">
      <th colspan="2" class="record-title">Stock Item Details</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Label:</td>
      <td class="detail-label">{{ stockItem.stockItemAnnotationData?.label | ellipsis:30 }}</td>
    </tr>
    <tr>
      <td>Commercial Reference:</td>
      <td class="detail-label">{{ getCommercialRef() }}</td>
    </tr>
    <tr>
      <td>Serial Number:</td>
      <td class="detail-label">{{ getSerialNumber() }}</td>
    </tr>
    <tr>
      <td>Stock Item Id:</td>
      <td class="detail-label">{{ stockItem.stockItemId }}</td>
    </tr>
    <tr>
      <td>Stock Item Set Id:</td>
      <td class="detail-label">{{ stockItem.stockItemSetId }}</td>
    </tr>
    <tr>
      <td>Stock Item Data:</td>
      <td class="detail-label">
        <pre>{{ stockItem.stockItemAnnotationData | json }}</pre>
      </td>
    </tr>

    <tr>
      <td>Stock Item Set Data:</td>
      <td class="detail-label">
        <pre>{{ stockItem.stockItemSetAnnotationData | json }}</pre>
      </td>
    </tr>
    </tbody>
  </table>
</mat-card>

<mat-card *ngIf="!stockItem">
  <mat-card-content>
    Missing
  </mat-card-content>
</mat-card>

