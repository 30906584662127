import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../user.service';
import {zip} from 'rxjs';
import {ConfigService} from "../../base/config.service";
import {DemoConfig} from "../../../app.module";
import {DatasetSourceRoles, UserInfo} from "../user-models";
import {GatewayException} from "../../base/gateway-exception";
import {userFormValidator, UserStatus} from "../user-mgt/user-mgt.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";

@Component({
  selector: 'ae-exp-user-mgt',
  templateUrl: './expanded-user-mgt.component.html',
  styleUrls: ['./expanded-user-mgt.component.scss']
})
export class ExpandedUserMgtComponent implements OnInit {

  constructor(private userService: UserService, private configSvc: ConfigService<DemoConfig>, formBuilder: FormBuilder) {
    this.filterForm = formBuilder.group({
      filterStatus: this.filterStatusFormCtl
    });

    this.userForm = formBuilder.group({
      userName: this.userNameFormCtl,
      userRoles: this.userSuperuserFormCtl,
      userEnabled: this.userEnabledFormCtl,
      userIsNew: this.userIsNewCtl
    }, {validators: userFormValidator});
  }

  @ViewChild('usersTop') usersTopElement!: ElementRef;
  @ViewChildren('usersList') usersListElements!: QueryList<ElementRef>;

  showFilter = true;
  currentUserId: string | null = null;
  currentUser: UserInfo | null = null;
  users: UserInfo[] | null = null;
  availableDatasets: DatasetSourceRoles[] | null = null;
  availableSourceRoles: string[] = [];
  defaultDataset: string | null = null;

  ENABLED_STATUS = new UserStatus(true, 'Enabled');
  DISABLED_STATUS = new UserStatus(false, 'Disabled');
  statuses: UserStatus[] = [this.ENABLED_STATUS, this.DISABLED_STATUS];

  filterForm: FormGroup;
  filterStatusFormCtl = new FormControl();

  // user editing
  inEdit = false;
  showDelete = false;
  userFormTitle = '';
  userForm: FormGroup;
  userNameFormCtl = new FormControl();
  userSuperuserFormCtl = new FormControl();
  userEnabledFormCtl = new FormControl();
  userIsNewCtl = new FormControl();
  formErrors: string[] = [];


  ngOnInit(): void {
    const datasetSourceRolesSub = this.userService.listAssignableDataSets();
    datasetSourceRolesSub.subscribe({
      next: (datasets) => {
        this.availableDatasets = datasets;
        this.defaultDataset = datasets?.length > 0 ? datasets[0].name : null;
        this.availableSourceRoles = datasets?.length > 0 ? datasets[0].assignableSourceRoles : [];
        this.resetFilter();
      },
      error: error => {
        console.log('Error initializing user management datasets', error);
      }
    });
  }

  protected clearCurrent(): void {
    this.currentUser = null;
    this.currentUserId = null;
    if (this.userIsNewCtl.value !== true) {
      this.cancelEdit();
    }
  }

  protected doneEditing(): void {
    this.clearCurrent();
    this.doFilter();
  }

  hasSourceRole(sourceRole: string, dataset: string): boolean {
    return this.currentUser?.sourceRoles?.find(sr => sr.sourceRole == sourceRole && sr.datasetName == dataset) != null || false;
  }

  hideFilter(): void {
    this.showFilter = false;
  }

  resetFilter(): void {
    this.clearCurrent();
    this.users = null;
    const statuses = this.statuses
      .filter(it => it === this.ENABLED_STATUS)
      .map(it => it.enabled);
    this.filterStatusFormCtl.setValue(statuses);
  }

  doFilter(): void {
    this.users = null;
    this.showFilter = false;
    const formValue = this.filterForm.value;
    const status: boolean[] = formValue.filterStatus;
    if (this.availableDatasets && this.defaultDataset) {
      this.userService.listUsers(this.defaultDataset).subscribe(users => {

        // filter the full list against the desired filter params
        const filtered = users
          .filter(user => !user.superuser)
          .filter(user => status.some(s => s === user.enabled))
          .sort((userA, userB) => userA.email.localeCompare(userB.email));

        this.users = filtered;

        if (filtered.length > 0) {
          this.hideFilter();
        }
      });
    }
  }

  protected selectUser(id: string, i: number) {
    this.currentUserId = id;
    this.getAndSetupCurrentUser(id);
    this.showDelete = true;
  }

  private setCurrentUser(user: UserInfo): void {
    this.currentUser = user;
    this.userNameFormCtl.setValue(user.email);
    this.userSuperuserFormCtl.setValue(user.superuser);
    this.userEnabledFormCtl.setValue(user.enabled);
    this.userIsNewCtl.setValue(false);
    this.userFormTitle = 'Edit User';
    this.userNameFormCtl.disable();
    this.inEdit = true;
  }

  newUser(): void {
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    this.userIsNewCtl.setValue(true);
    this.userEnabledFormCtl.setValue(true);
    this.userFormTitle = 'Enter New User';
    this.inEdit = true;
    this.showDelete = false;
  }

  cancelEdit(): void {
    this.clearFormErrors();
    this.inEdit = false;
    this.resetUserForm();
    this.currentUserId = null;
    this.currentUser = null;
    if (!this.users || this.users.length < 1) {
      this.showFilter = true;
    }
  }

  protected resetUserForm(): void {
    this.userFormTitle = '';
    this.userNameFormCtl.setValue('');
    this.userNameFormCtl.enable();
    this.userEnabledFormCtl.setValue(false);
    this.userSuperuserFormCtl.setValue(false);
    this.userIsNewCtl.setValue(null);
    this.userForm.reset();
  }

  formatYesNo(value?: boolean): string {
    return (value || false) ? "Yes" : "No";
  }

  changeSourceRole(isChecked: boolean, sourceRole: string, dataset: string): void {
    if (isChecked) {
      this.addSourceRole(sourceRole, dataset);
    } else {
      this.removeSourceRole(sourceRole, dataset);
    }
  }

  changeStatus(isEnabled: boolean) {
    if (this.currentUser) {
      //call backend
      this.userService.saveUser(this.currentUser?.email!, isEnabled).subscribe((user: UserInfo) => {
      }, (error: any) => console.log(error));
    }
  }

  getAndSetupCurrentUser(userId: string): void {
    const streams: Observable<any>[] = [];
    this.availableDatasets?.map(ds => ds.name).forEach(datasetName => {
      streams.push(this.userService.getUser(userId, datasetName));
    });
    zip(streams).subscribe((userInfos: UserInfo[]) => {
      const allDatasetSourceRoles = userInfos.map(ui => ui.sourceRoles).flat();
      const ui = userInfos[0];
      const userInfo = new UserInfo(ui.id, ui.email, ui.customerName, ui.enabled, ui.superuser, allDatasetSourceRoles);
      this.currentUserId = userId;
      this.currentUser = userInfo;
      this.setCurrentUser(this.currentUser);
    }, (error: any) => console.log(error))
  }

  addSourceRole(sourceRole: string, dataset: string): void {
    if (this.currentUser) {
      //call backend
      this.userService.addSourceRole(this.currentUser?.id!, sourceRole, dataset!).subscribe(() => {
      }, (error: any) => console.log(error));
    }
  }

  removeSourceRole(sourceRole: string, dataset: string): void {
    if (this.currentUser) {
      //call backend
      this.userService.removeSourceRole(this.currentUser?.id!, sourceRole, dataset).subscribe(() => {
      }, (error: any) => console.log(error));
    }
  }

  createUser(): void {
    if (this.userForm.valid) {
      this.clearFormErrors();
      const frm = this.userForm.value;

      const _this = this;

      // create user
      if (frm.userIsNew) {
        this.userService.saveUser(frm.userName, frm.userEnabled).subscribe({
          next: user => {
            this.cancelEdit();
            this.doFilter();
          },
          error: ((error: GatewayException) => {
            console.log('ERROR creating user', error);
            if (!_this.formErrors.includes(error.messages?.join("\n") as string)) {
              _this.formErrors.push(error.messages?.join("\n") as string);
            }
          })
        });
      }
    }
  }

  canSubmit(): boolean {
    let can = false;
    if (this.userIsNewCtl.value === true) {
      can = this.userForm?.errors == null;
    } else {
      can = (this.userSuperuserFormCtl.dirty || this.userEnabledFormCtl.dirty)
        && this.userForm?.errors == null;
    }

    return can;
  }

  clearFormErrors(): void {
    this.formErrors = [];
  }

  formatDataset(dataset: string): string {
    if (!dataset) {
      return "";
    } else {
      return `${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")}`
    }
  }
}
