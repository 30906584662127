import {Component} from '@angular/core';

@Component({
    selector: 'ae-superuser-mgt-wrapper',
    templateUrl: './superuser-mgt-wrapper.component.html',
    styleUrls: []
})
export class SuperuserMgtWrapperComponent {

}
