<mat-toolbar color="primary" class="bg-gradient">
  <div class="header-logo" routerLink="/dashboard">
    <img alt="demo" src="/assets/alitheon-logo.png">
  </div>
  <span class="header-spacer">
      <a class="header-link" routerLink="/dashboard"
         routerLinkActive="header-link-active">Dashboard</a> |
      <a class="header-link" *ngIf="canView" routerLink="/identifications" routerLinkActive="header-link-active">Identifications</a>
      <span *ngIf="canView"> | </span>
      <a class="header-link" *ngIf="canView" routerLink="/featurePrints" routerLinkActive="header-link-active">FeaturePrints</a>
      <span *ngIf="canView"> | </span>
      <a class="header-link" *ngIf="canManage" routerLink="/user-mgt" routerLinkActive="header-link-active">User Management</a>
      <span *ngIf="canManage"> | </span>
      <a class="header-link" *ngIf="isSuperuser" routerLink="/superuser-mgt" routerLinkActive="header-link-active">Superuser Management</a>
      <span *ngIf="isSuperuser"> | </span>
      <a class="header-link" routerLink="/logout" routerLinkActive="header-link-active">Logout</a>
</span>
  <div class="text-right">
    <div>
      <sup>{{ now | date: 'MMMM d, y  HH:mm:ss' }}</sup>
    </div>
    <div>
      <sup>Last Updated: {{ lastRefreshed | date: 'HH:mm:ss' }} </sup>
    </div>
  </div>
</mat-toolbar>
