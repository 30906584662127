<div>
  <h2>Statistics</h2>
  <table class="table table-sm fp-stats">
    <thead>
    <tr class="fp-stats-header">
      <th class="preview-label" style="text-align: left;">Dataset</th>
      <th class="preview-label">FeaturePrints for the last day:</th>
      <th class="preview-label">Total FeaturePrints:</th>
      <th class="preview-label">Identifications for the last day:</th>
      <th class="preview-label">Total Identifications:</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of totals">
      <td style="text-align: left" class="fp-stats-header">{{t.dataset}}</td>
      <td>{{t.dailyFeaturePrintCount| number:'1.0':'en-US'}}</td>
      <td>{{t.totalFeaturePrintCount| number:'1.0':'en-US'}}</td>
      <td>{{t.dailyIdentificationCount| number:'1.0':'en-US'}}</td>
      <td>{{t.totalIdentificationCount| number:'1.0':'en-US'}}</td>
    </tr>
    </tbody>
  </table>
</div>
