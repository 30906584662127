/* tslint:disable:no-console */
import {Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {timer, zip} from 'rxjs';
import {Page, PortalApiService, SearchCriteria} from '../../modules/record/portal-api.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {CoreService, LookupLists} from "../../modules/core.service";
import {StockItemSearchResult, StockItemSelector} from "../../modules/record/feature-print-models";

@Component({
  selector: 'ae-featureprint-list',
  templateUrl: './featureprint-list.component.html',
  styleUrls: ['./featureprint-list.component.scss']
})
export class FeatureprintListComponent implements OnInit {
  lookups: LookupLists = new LookupLists([]);
  datasetNames: string[] = [];
  currentStockItem: any;
  searchDataset: string | null = null;
  selectedStockItem: StockItemSelector | null = null;

  searchCriteria!: SearchCriteria;
  stockItems: StockItemSearchResult[] | null = null;
  searchResultsTotal = 0;
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [5, 10, 15, 20, 25, 50];
  @ViewChild('paginator')
  paginator!: MatPaginator;
  @ViewChild('stockItemTop')
  regTopElement!: ElementRef;
  @ViewChildren('stockItemList')
  regListElements!: QueryList<ElementRef>;

  constructor(private recordApi: PortalApiService, private coreSvc: CoreService) {
  }

  ngOnInit(): void {
    this.coreSvc.getLookupLists().subscribe(lookups => {
      this.lookups = lookups;
      this.datasetNames = this.lookups.dataSets.map(dsRole => dsRole.name);
    });
  }

  public resetSearchHandler() {
    this.pageIndex = 0;
    this.stockItems = null;
    this.searchResultsTotal = 0;
    this.currentStockItem = null;
    this.selectedStockItem = null;
  }

  public searchHandler(searchCriteria: SearchCriteria): void {
    this.stockItems = null;
    this.currentStockItem = null;
    this.searchCriteria = searchCriteria;
    this.doSearch();
  }

  doSearch(): void {
    if (this.searchCriteria) {
      console.log(this.searchCriteria);
      this.searchCriteria.page = new Page(this.paginator?.pageIndex || 0, this.pageSize);
      const searchStream = this.recordApi.searchStockItems(this.searchCriteria);
      const countStream = this.recordApi.featurePrintSearchCount(this.searchCriteria);
      zip(searchStream, countStream).subscribe({
        next: ([records, cnt]) => {
          this.stockItems = records;
          this.searchResultsTotal = cnt;
          this.searchDataset = this.searchCriteria.dataset;
        },
        error: errors => {
          this.searchResultsTotal = 0;
          this.stockItems = [];
          console.log('error searching registrations', errors);
        }
      });
    }
  }

  pageHandler(event: PageEvent): void {
    this.currentStockItem = null;
    this.pageSize = this.paginator.pageSize;
    this.doSearch();
  }

  registrationChangeHandler(event: string): void {
    this.doSearch();
  }

  @Input('searchResults') set searchResults(list: StockItemSearchResult[]) {
    if (list) {
      this.stockItems = list;
      if (this.stockItems.length > 0) {
        this.selectTopOfList();
      } else {
        this.currentStockItem = null;
      }
    }
  }

  protected clearCurrent(): void {
    console.log("clear selected");
    this.currentStockItem = null;
    this.selectedStockItem = null;
  }

  protected viewCaptureAndScroll(id: string, i: number, scroll: boolean): void {
    this.currentStockItem = id;
    this.selectedStockItem = {stockItemId: id, dataset: this.searchDataset!};
    if (scroll && id) {
      timer(50).subscribe(() => {
        this.regListElements.toArray()[i].nativeElement.scrollIntoViewIfNeeded({behavior: 'auto'});
        timer(250).subscribe(() => {
          this.regTopElement.nativeElement.scrollIntoViewIfNeeded({behavior: 'smooth'});
        });
      });
    }
  }

  protected viewCapture(i: number, id?: string): void {
    if (!id) {
      return;
    }
    const doScroll: boolean = this.currentStockItem ? false : true;
    this.viewCaptureAndScroll(id, i, doScroll);
  }

  private selectTopOfList(): void {
    if (!this.stockItems || this.stockItems.length === 0) {
      return;
    }

    const list = this.stockItems;

    if (list.length > 0) {
      let index = 0;
      if (this.currentStockItem) {
        index = list.findIndex(r => r.stockItemId == this.currentStockItem);
        index = index < 0 ? 0 : index;
      }
      this.viewCaptureAndScroll(list[index]?.stockItemId, index, true);
    } else {
      this.clearCurrent();
    }
  }
}
