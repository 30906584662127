<div #usersTop class="search-filter">
  <mat-card *ngIf="users && users.length > 0">
    <div class="header-menu">
      <div class="header-menu-title"></div>
      <button mat-stroked-button (click)="listUsers();">
        <span style="font-weight: bold">Refresh</span>
      </button>
    </div>

    <div class="scroll-pane scroll-pane-all">
      <table class="table table-sm table-hover table-striped">
        <thead>
        <tr>
          <th>User ID</th>
          <th>Email</th>
          <th>Superuser</th>
          <th>Enabled</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let u of users; index as i" #usersList>
          <td>{{ u.id }}</td>
          <td>{{ u.email }}</td>
          <td>
            <mat-checkbox id="user-superuser" [checked]="isSuperuser(u.id)" (change)="changeSuperuser(u.email, u.enabled, $event.checked)"></mat-checkbox>
          </td>
          <td>
            <mat-checkbox id="user-enabled" [checked]="isEnabled(u.id)" (change)="changeSuperuser(u.email, $event.checked, u.superuser)"></mat-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-card>
  <mat-card *ngIf="users && users.length === 0">
    <mat-card-content>
      No Matching Users
    </mat-card-content>
  </mat-card>


</div>
