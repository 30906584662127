import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UserService} from '../user.service';
import {UserInfo} from "../user-models";
import {Router} from "@angular/router";

@Component({
  selector: 'ae-superuser-mgt',
  templateUrl: './superuser-mgt.component.html',
  styleUrls: ['./superuser-mgt.component.scss']
})
export class SuperUserMgtComponent implements OnInit {

  constructor(private userService: UserService, private router: Router) {
  }

  @ViewChild('usersTop') usersTopElement!: ElementRef;
  @ViewChildren('usersList') usersListElements!: QueryList<ElementRef>;

  defaultDataset: string | null = null;
  users: UserInfo[] | null = null;
  formErrors: string[] = [];

  ngOnInit(): void {
    this.userService.listAssignableDataSets()
      .subscribe({
        next: (datasets) => {
          if (datasets && datasets.length > 0) {
            this.defaultDataset = datasets[0].name;
            this.listUsers();
          }
        },
        error: error => {
          console.log('Error initializing superuser management datasets', error);
        }
      });
  }

  isEnabled(id: string): boolean {
    return this.users?.find(u => u.id == id)?.enabled || false;
  }

  isSuperuser(id: string): boolean {
    return this.users?.find(u => u.id == id)?.superuser || false;
  }

  listUsers(): void {
    this.users = null;
    this.userService.listForSuperusers(this.defaultDataset!).subscribe(users => {

      // filter the full list against the desired filter params
      const filtered = users
        .filter(user => user.email.endsWith("alitheon.com"))
        .sort((userA, userB) => userA.email.localeCompare(userB.email));
      this.users = filtered;
    }, error => {
      this.userService.isSuperuser().subscribe(isSuperuser => {
        if(!isSuperuser) {
          this.router.navigate([""]);
        }
      })
    });
  }

  protected changeSuperuser(email: string, isEnabled: boolean, isSuperuser: boolean) {
    this.userService.saveSuperUser(email, isEnabled, isSuperuser).subscribe((updated) => {
      if (updated.email != email || updated.enabled != isEnabled || updated.superuser != isSuperuser) {
        console.log("Error, superuser status was not updated");
        this.listUsers();
      } else {
        const index = this.users?.findIndex(u => u.email == email);
        if (index && index > -1) {
          this.users!![index].superuser = isSuperuser;
          this.users!![index].enabled = isEnabled;
        }
      }
    }, (error: any) => {
      console.log("Error saving SUPERUSER status", error);
      this.listUsers();
    })
  }

  formatYesNo(value?: boolean): string {
    return (value || false) ? "Yes" : "No";
  }

  clearFormErrors(): void {
    this.formErrors = [];
  }

}
