import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PortalApiService} from '../../modules/record/portal-api.service';
import {UserService} from "../../modules/user/user.service";
import {Facet, StockItem, StockItemSelector} from "../../modules/record/feature-print-models";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'ae-stock-item',
  templateUrl: './stock-item.component.html',
  styleUrls: ['./stock-item.component.scss']
})
export class StockItemComponent {

  @Input() stockItem: StockItem | null = null;

  getSerialNumber(): string {
    try {
      const jason = JSON.parse(this.stockItem?.externalIdentifier as string);
      return jason["serialNumber"] as string;
    } catch (e:any) {
      return "";
    }
  }
  getCommercialRef(): string {
    try {
      const jason = JSON.parse(this.stockItem?.externalIdentifier as string);
      return jason["commercialReference"] as string;
    } catch (e:any) {
      return "";
    }
  }
}
