/*
{
  "principalGrant": {
    "id": "01905547-5c33-7d2a-ac7d-94d54f75437d",
      "email": "shannon@alitheon.com",
      "customerName": "richemont",
      "enabled": true,
      "superuser": false,
      "sourceRoles": [
        {
          "userId": "01905541-22e1-70b9-8911-f6a68de384b1",
          "customerName": "richemont",
          "datasetName": "panerai",
          "sourceRole": "STATION-BASE",
          "created": "2024-07-29T19:13:53.703239Z",
          "createdBy": "authz-foundation"
        }
      ],
      "created": "2024-08-13T11:04:13.522252Z",
      "createdBy": "authz-foundation",
      "lastModified": "2024-08-13T19:40:26.204379Z",
      "modifiedBy": "authz-foundation"
  },
  "tokenDetails": {
    "accessToken": "...",
    "expiresIn": 86400,
    "refreshToken": "...",
    "refreshTokenExpiresIn": 2592000
  }
}
*/

export interface SourceRole {
  sourceRole: string
  datasetName: string
  datasetId: string
  userId: string
}

export interface UserInfoResponse {
  id: string;
  email: string;
  enabled: boolean;
  superuser: boolean;
  roles: string[];
  sourceRoles: SourceRole[];
  customerName: string;
}

export class UserInfo {
  id: string;
  email: string;
  enabled: boolean;
  superuser: boolean;
  sourceRoles: SourceRole[];
  customerName: string;

  constructor(
    userId: string,
    email: string,
    customerName: string,
    enabled: boolean,
    superuser: boolean,
    sourceRoles: SourceRole[]
  ) {
    this.id = userId;
    this.email = email;
    this.enabled = enabled;
    this.superuser = superuser;
    this.sourceRoles = sourceRoles;
    this.customerName = customerName;
  }

  static fromUserInfoGetResponse(userInfo: UserInfoResponse): UserInfo {
    const SUPERUSER = "SUPERUSER";
    const newUserInfo = new UserInfo(
      userInfo.id,
      userInfo.email,
      userInfo.customerName,
      userInfo.enabled,
      userInfo.superuser || userInfo.roles.includes(SUPERUSER),
      userInfo.sourceRoles
    );
    return newUserInfo;
  }
}

/*
{
   "assignableDatasets": [
     {
       "name": "dataset1",
       "assignableSourceRoles": [
         "A", "B", "C"
       ]
     },
     {
       "name": "dataset2",
       "assignableSourceRoles": [
         "B", "C", "D"
       ]
     }
   ]
}
 */

export class DatasetSourceRoles {
  name: string;
  assignableSourceRoles: string[] = [];

  constructor(name: string, sourceRoles: string[]) {
    this.name = name;
    this.assignableSourceRoles = sourceRoles
  }
}
