import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PortalApiService} from '../../modules/record/portal-api.service';
import {UserService} from "../../modules/user/user.service";
import {Facet, StockItem, StockItemSelector} from "../../modules/record/feature-print-models";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'ae-featureprint',
  templateUrl: './featureprint.component.html',
  styleUrls: ['./featureprint.component.scss']
})
export class FeatureprintComponent implements OnInit {
  private stockItemStream: Subscription | null = null;
  private facetsStream: Subscription | null = null;

  dataset: string | null = null;
  public stockItemId: string | null = null;
  public stockItem: StockItem | null = null;
  public facets: Facet[] | null = null;

  @Input() fullView = true;
  @Input() showHeader = true;

  @Input() set stockItemSelector(selector: StockItemSelector | null) {
    if(selector) {
      this.stockItemId = selector.stockItemId;
      this.dataset = selector.dataset;
      if (this.stockItemId) {
        this.getStockItem();
      } else {
        this.clear();
      }
    }
  }

  @Output() stockItemChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private recordApi: PortalApiService,
              private route: ActivatedRoute,
              private userSvc: UserService) {
  }

  ngOnInit(): void {
    this.getStockItem();
  }

  clear(): void {
    this.stockItemId = null;
    this.dataset = null;
    this.stockItem = null;
    this.facets = null;
  }

  getStockItem(): void {
    if (!this.stockItemId) {
      this.stockItemId = this.route.snapshot.paramMap.get('stockItemId');
    }
    if (!this.dataset) {
      this.dataset = this.route.snapshot.paramMap.get("dataset");
    }
    if (this.stockItemStream) {
      this.stockItemStream.unsubscribe();
    }
    if (this.facetsStream) {
      this.facetsStream.unsubscribe();
    }

    if (this.stockItemId && this.dataset) {
      this.stockItemStream = this.recordApi.getStockItem(this.stockItemId, this.dataset).subscribe(stockItem => {
        this.stockItem = stockItem;
      });

      this.facetsStream = this.recordApi.getFacets(this.stockItemId, this.dataset).subscribe(facets => {
        this.facets = facets;
      })
    }
  }
}
