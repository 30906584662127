<ae-nav></ae-nav>
<div>
    <h1 class="main-view"> Dashboard </h1>

    <div class="dashboard-container">

        <div class="row">
            <div class="col-md-12 fp-graph">
              <div class="col-md-12 fp-graph">
                <h2>Total Daily FeaturePrints and Identifications</h2>
                <ae-fp-chart [setDataSets]="datasets"></ae-fp-chart>
              </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-card class="fp-card">
                    <mat-card-content class="fp-content">
                        <ae-fp-stats [setDataSets]="datasets"></ae-fp-stats>
                    </mat-card-content>
                </mat-card>
            </div>

<!--            <div class="col-md-6">-->
<!--                <mat-card class="fp-card">-->
<!--                    <mat-card-content class="fp-content">-->
<!--                        <ae-non-matches></ae-non-matches>-->
<!--                    </mat-card-content>-->
<!--                </mat-card>-->
<!--            </div>-->
        </div>
    </div>
</div>
