<ae-nav></ae-nav>
<div>

  <div>
    <div class="search-filter">
      <ae-search-filter class="search-filter"
                        [searchTitle]="'Identifications Search'"
                        [includeDateSearch]="true"
                        [includeLabelSearch]="true"
                        [dataSetList]="datasetNames"
                        (searchEvent)="searchHandler($event)"
                        (resetSearchEvent)="resetHandler()"
      >
      </ae-search-filter>
    </div>

    <div #authTop class="search-filter">
      <mat-card *ngIf="searchResult && searchResult.length > 0">
        <div class="header-menu">
          <h3 class="header-menu-title">Identifications Count: {{ searchResultCount || 0 }}</h3>
          <button *ngIf="currentDisplayId" mat-raised-button (click)="clearCapture();">
            Clear Selection
          </button>
        </div>

        <div class="scroll-pane scroll-pane-all"
             [ngClass]="{'scroll-pane-detail': currentDisplayId}">
          <table class="table table-sm table-hover table-striped">
            <thead>
            <tr>
              <th>Identification ID</th>
              <th>Expected Item</th>
              <th>Identification Outcome</th>
              <th>Identified Item</th>
              <th>Station</th>
              <th>User ID</th>
              <th>Created</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let a of searchResult; index as i" #authList
                [ngClass]="{'selected': currentDisplayId == a?.identificationId}"
                class="link-active"
                (click)="currentDisplayId == a.identificationId ? clearCapture() : viewCapture(i, a.identificationId)">
              <td>
                <a *ngIf="a.identificationId" class="link-active" target="_blank"
                   matTooltip="select link to open authentication in new tab"
                   [routerLink]="['/identification', this.searchDataset, a.identificationId]"> {{ a.identificationId }} </a>
                <span *ngIf="!a.identificationId">Not Available</span>
              </td>
              <td matTooltip="click row for details">{{ a.identificationLabel | ellipsis:30 }}</td>
              <td>
                <mat-icon *ngIf="a.bestMatchStockItemId && a.identificationLabel == a.stockItemLabel"
                          color="primary" matTooltip="FeaturePrint matched the expected item.">
                  check
                </mat-icon>
                <mat-icon *ngIf="a.bestMatchStockItemId && a.identificationLabel != a.stockItemLabel"
                          class="mismatch" matTooltip="Requires review. FeaturePrint identified a different item than expected.">
                  warning
                </mat-icon>
                <mat-icon *ngIf="!a.bestMatchStockItemId" color="warn"
                          matTooltip="FeaturePrint did not identify any item. The expected item may not have been FeaturePrinted.">
                  close
                </mat-icon>
              </td>
              <td matTooltip="click row for details">{{ a.stockItemLabel | ellipsis:30 }}</td>
              <td matTooltip="click row for details">{{ a.stationId }}</td>
              <td matTooltip="click row for details">{{ a.author }}</td>
              <td matTooltip="click row for details">{{ a.created | date: "yyyy-MM-dd HH:mm:ss" }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
      <mat-paginator #paginator
                     [length]="searchResultCount || 0"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     [showFirstLastButtons]="true"
                     [hidden]="searchResultCount < 1"
                     (page)="pageHandler($event)"
      ></mat-paginator>

      <mat-card *ngIf="searchResult &&  searchResult.length === 0" class="no-match">
        <mat-card-content>
          No Matching Records
        </mat-card-content>
      </mat-card>

      <ae-identification *ngIf="currentDisplayId && currentIdentification"
                         [fullView]="false"
                         [identificationRecord]="currentIdentificationWithDataset"
      ></ae-identification>

    </div>
  </div>
</div>
