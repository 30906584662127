<ae-nav *ngIf="fullView"></ae-nav>
<div *ngIf="showHeader && stockItemId" class="container-fluid record-header">
  <mat-card>
    <div class="center-title">
      <mat-card-header>
        <mat-card-title>
          StockItem FeaturePrint: {{ stockItem?.stockItemAnnotationData?.label }}
        </mat-card-title>
      </mat-card-header>
    </div>
  </mat-card>
</div>
<div class="container-fluid" *ngIf="stockItemId">
  <div class="row row-padded">
    <div class="col-md-6">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let facet of facets">
          <mat-expansion-panel-header>{{facet.facetName}} - {{facet.status}}</mat-expansion-panel-header>
          <ae-facet [facetInput]="{facet: facet!, dataset: dataset!}"></ae-facet>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-md-6">
      <ae-stock-item [stockItem]="stockItem"></ae-stock-item>
    </div>
  </div>
</div>
<div class=container-fluid *ngIf="!stockItemId">
  <mat-card>
    <mat-card-content>
      Missing
    </mat-card-content>
  </mat-card>
</div>
