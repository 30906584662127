import {NgModule} from "@angular/core";
import {UserService} from "./user.service";
import {UserMgtComponent} from "./user-mgt/user-mgt.component";
import {BrowserModule} from "@angular/platform-browser";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {AngularMaterialModule} from "../angular-material.module/angular-material.module.module";
import {LogoutComponent} from "./logout/logout.component";
import {LoginComponent} from "./login/login.component";
import {SuperUserMgtComponent} from "./superuser-mgt/superuser-mgt.component";
import {ExpandedUserMgtComponent} from "./expanded-user-mgt/expanded-user-mgt.component";

@NgModule({
  declarations: [LogoutComponent, UserMgtComponent, ExpandedUserMgtComponent, SuperUserMgtComponent, LoginComponent],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    AngularMaterialModule,
  ],
  providers: [
    UserService
  ],
  exports: [LogoutComponent, UserMgtComponent, ExpandedUserMgtComponent, SuperUserMgtComponent, LoginComponent],
  bootstrap: []
})
export class UserModule {
}
