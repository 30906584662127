<mat-card *ngIf="facet" class="record-card">
  <div>
    <table class="table table-sm table-hover table-striped">
      <tr style="border:none">
        <th colspan="2" class="record-title">FeaturePrint</th>
      </tr>
      <tbody>
      <tr class="preview-wrapper-row">
        <td class="preview-wrapper" colspan="2">
          <img alt="preview" class="preview preview-sm" *ngIf="previewImageUrl"
               [src]="previewImageUrl | previewImage | async"
          >
        </td>
      </tr>
      <tr>
        <td class="preview-label">Facet Id:</td>
        <td>{{facet.facetId}}</td>
      </tr>
      <tr>
        <td class="preview-label">Name:</td>
        <td>{{facet.facetName}}</td>
      </tr>
      <tr>
        <td class="preview-label">View Type:</td>
        <td>{{facet.viewType}}</td>
      </tr>
      <tr>
        <td class="preview-label">Station Id:</td>
        <td>{{facet.stationId | ellipsis: 30}}}</td>
      </tr>
      <tr>
        <td class="preview-label">Station Location:</td>
        <td> {{facet.stationLocation | ellipsis: 30}}}</td>
      </tr>
      <tr>
        <td class="preview-label">Station Version:</td>
        <td>{{facet.stationVersion | ellipsis: 30}}</td>
      </tr>
      <tr>
        <td class="preview-label">Status</td>
        <td>{{facet.status}}</td>
      </tr>
      <tr>
        <td class="preview-label">Created</td>
        <td>{{facet.created | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
      </tr>
      <tr>
        <td class="preview-label">Data</td>
        <td><pre>{{facet.facetData | json }}</pre></td>
      </tr>
      </tbody>
    </table>
  </div>
</mat-card>


