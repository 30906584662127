import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PeriodicRefreshService} from '../../modules/utility/periodic-refresh.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {Counts, CountsByDay, PortalApiService} from "../../modules/record/portal-api.service";
import {UserService} from "../../modules/user/user.service";
import {zip} from "rxjs";
import {Observable} from "rxjs/internal/Observable";

@Component({
  selector: 'ae-fp-stats',
  templateUrl: './featureprint-stats.component.html',
  styleUrls: ['./featureprint-stats.component.scss']
})
export class FeatureprintStatsComponent implements OnInit, OnDestroy {
  private refreshSub: Subscription | null = null;

  constructor(private refreshSvc: PeriodicRefreshService,
              private portalApi: PortalApiService,
              private userService: UserService
  ) {
  }

  protected dataSets: Array<string> = [];
  totals: StatsByDataset[] = [];

  @Input() set setDataSets(val: string[]) {
    if (val && val != this.dataSets) {
      this.dataSets = val;
      this.loadStats();
    }
  }

  ngOnInit(): void {
    this.refreshSub = this.refreshSvc.getLastRefreshed().subscribe(refreshed => {
      //todo
    });
  }

  ngOnDestroy(): void {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
      this.refreshSub = null;
    }
  }

  loadStats(): void {
    const datasetNames: DatasetNameLookup[] = [];
    const streams: Observable<any>[] = [];
    const data: StatsByDataset[] = [];

    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - (1 * this.portalApi.dayInMillis));

    this.dataSets.forEach((dataset, index) => {
      datasetNames.push({dataset: `${dataset}-tfp`, index: index} as DatasetNameLookup);
      datasetNames.push({dataset: `${dataset}-dfp`, index: index} as DatasetNameLookup);
      datasetNames.push({dataset: `${dataset}-tid`, index: index} as DatasetNameLookup);
      datasetNames.push({dataset: `${dataset}-did`, index: index} as DatasetNameLookup);
      const formattedDataset = `${dataset.split('-').map(w => w = `${w[0].toUpperCase()}${w.substring(1)}`).join(" ")}`;
      data.push({dataset: `${formattedDataset}`, totalFeaturePrintCount: 0, dailyFeaturePrintCount: 0, totalIdentificationCount: 0, dailyIdentificationCount: 0});
      streams.push(this.portalApi.totalFeaturePrints(dataset));
      streams.push(this.portalApi.featurePrintCountsByDay(dataset, startDate, endDate));
      streams.push(this.portalApi.totalIdentifications(dataset));
      streams.push(this.portalApi.identificationCountsByDay(dataset, startDate, endDate));
    });

    zip(streams).subscribe((totals) => {
      totals.forEach((total, index) => {
        const datasetTotalsName = datasetNames[index];
        let cnt = 0;
        //determine which totals this is
        const countsType = datasetTotalsName.dataset.substring(datasetTotalsName.dataset.length - 3);
        // if this is a totals call we get back a Counts record
        if (countsType == "tfp" || countsType == "tid") {
          cnt = (total as Counts).count;
        } else {
          // otherwise this is a CountsByDay record and we only want the very last one if it is today
          const cntByDay = (total as CountsByDay[]);
          if (cntByDay && cntByDay.length > 0) {
            const last = cntByDay[cntByDay.length - 1];
            //make sure the day is today and not last night
            if (endDate.toISOString().startsWith(last.day)) {
              cnt = last.count;
            }
          }
        }
        switch (countsType){
          case "tfp":
            data[datasetTotalsName.index].totalFeaturePrintCount = cnt;
            break;
          case "dfp":
            data[datasetTotalsName.index].dailyFeaturePrintCount = cnt;
            break;
          case "tid":
            data[datasetTotalsName.index].totalIdentificationCount = cnt;
            break;
          case "did":
            data[datasetTotalsName.index].dailyIdentificationCount = cnt;
            break;
        }
      });
      this.totals = data;
    });
  }


}

export interface DatasetNameLookup {
  dataset: string;
  index: number;
}

export interface StatsByDataset {
  dataset: string;
  totalFeaturePrintCount: number;
  dailyFeaturePrintCount: number;
  totalIdentificationCount: number;
  dailyIdentificationCount: number;
}

export interface DatasetTotal {
  dataset: string;
  total: number;
}
